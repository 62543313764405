import Vue from "vue"

export default {
  data () {
    return {
      title: { main: "Competo Portal", view: null, full: null }
    }
  },
  computed: {
    routeTitle () {
      if (this.$route.meta && this.$route.meta.title) {
        if (Vue.i18n.locale() === "sl-SI") {
          return this.$route.meta.title
        } else if (Vue.i18n.locale() === "en-EN") {
          let translations = {
            "Stran ne obstaja": "Page not found",
            "Prijava": "Log In",
            "Ponastavitev gesla": "Reset password",
            "Novo geslo": "New password",
            "Registracija": "Registration",
            "Registracija uspešna": "Registration successful",
            "Aktiviranje": "Activating",
            "Aktivacija uspešna": "Activation successful",
            "Aktivacija neuspešna": "Activation error",
            "Soglasja": "Consents",
            "Soglasje": "Consent",
            "Potrjevanje": "Confirming",
            "Potrjevanje uspešno": "Confirmation successful",
            "Potrjevanje neuspešno": "Confirmation error",
            "Soglasje je že potrjeno": "Consent has already been confirmed",
            "Vprašalniki": "Questionnaires",
            "Vprašalnik": "Questionnaire",
            "Nastavitve": "Settings",
            "Pregled": "Dashboard",
            "Delovno mesto": "Job",
            "Delovna mesta": "Jobs"
          }

          if (translations[this.$route.meta.title]) {
            return translations[this.$route.meta.title]
          }
        }
      }
      return ""
    }
  },
  watch: {
    routeTitle (to) {
      if (to || to === "") this.setTitle(to)
    }
  },
  methods: {
    setTitle (title) {
      this.title.view = title
      this.title.full = (title ? title + " | " : "") + this.title.main
      document.title = this.title.full
    }
  }
}
