import { ifAuthenticated, ifNotAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/consents",
    name: "Consents",
    meta: { title: "Soglasja" },
    component: () => import(/* webpackChunkName: "Consents List" */ "@/Modules/Consent/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/consents/:id",
    name: "Consent Details",
    meta: { title: "Soglasje" },
    component: () => import(/* webpackChunkName: "Consent Details" */ "@/Modules/Consent/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/consents/unsigned/:id",
    name: "Unsigned Consent",
    meta: { title: "Soglasje" },
    component: () => import(/* webpackChunkName: "Consent Details" */ "@/Modules/Consent/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/consent/confirm/:token",
    name: "Consent Confirmation",
    meta: { title: "Potrjevanje" },
    component: () => import(/* webpackChunkName: "Confirmation" */ "@/Modules/Consent/Views/Confirmation/Confirmation.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/consent/confirmation-success",
    name: "Consent Confirmation Success",
    meta: { title: "Potrjevanje uspešno" },
    component: () => import(/* webpackChunkName: "ConfirmationSucces" */ "@/Modules/Consent/Views/Confirmation/ConfirmationSuccess.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/consent/confirmation-error",
    name: "Consent Confirmation Error",
    meta: { title: "Potrjevanje neuspešno" },
    component: () => import(/* webpackChunkName: "ConfirmationError" */ "@/Modules/Consent/Views/Confirmation/ConfirmationError.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/consent/confirmation-confirmed",
    name: "Consent Confirmation Confirmed",
    meta: { title: "Soglasje je že potrjeno" },
    component: () => import(/* webpackChunkName: "ConfirmationError" */ "@/Modules/Consent/Views/Confirmation/ConfirmationConfirmed.vue"),
    beforeEnter: ifNotAuthenticated
  }
]
