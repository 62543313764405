export default {
  views: {
    list: {
      view_button: "Ogled soglasja",
      empty_list_notice: "Vaš seznam soglasij je prazen."
    },
    details: {
      header1: "Soglasje",
      header2: "Za nadaljevanje je potrebno soglasje",
      back_button: "Nazaj na seznam soglasij",
      confirm_button: "Potrdi soglasje"
    },
    confirmation: {
      headline: "Potrjevanje soglasja",
      headline_desc: "Soglasje se potrjuje. Kmalu boste preusmerjeni..."
    },
    confirmation_success: {
      headline: "Soglasje potrjeno",
      headline_desc: "Hvala za potrditev soglasja.",
      to_login: "Na prijavo"
    },
    confirmation_error: {
      headline: "Napaka pri potrjevanju soglasja",
      headline_desc: "Pri potrjevanju soglasja je prišlo do težave.",
      error_msg: "Sporočilo o napaki:",
      contact: "Za več informacij kontaktirajte administratorja."
    },
    confirmation_confirmed: {
      headline: "Soglasje je že potrjeno",
      headline_desc: "Do potrditve soglasja ni prišlo, ker je bilo soglasje že potrjeno.",
      to_login: "Na prijavo"
    }
  }
}
